import React, { useState } from 'react';

interface SortableColumnProps {
	sortable?: boolean;
	sortBy: string;
	style?: object;
	onSortChange?: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void;
}

const SortableColumn: React.FC<SortableColumnProps> = ({
	children,
	sortable = true,
	sortBy,
	style = {},
	onSortChange
}) => {
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | 'none'>('none');

	const toggleSort = () => {
		const newDirection = sortDirection === 'none' ? 'asc' : (sortDirection === 'asc' ? 'desc' : 'none');
		setSortDirection(newDirection);
		onSortChange?.(sortBy, newDirection);
	};

	return (
		<th
			onClick={sortable ? toggleSort : undefined}
			style={{
				cursor: sortable ? 'pointer' : 'default',
				userSelect: sortable ? 'none' : 'auto',
				position: 'relative',
				...style
			}}
		>
			<span >
				{children}
			</span>
			{sortable && (
				<span className={`sortable-icon`} style={{
					marginLeft: '7px',
					position: 'absolute',
					top: '50%',
					right: '150x',
					height: '100%',
					transform: 'translateY(-50%)'
				}}>
					<i className="bi bi-caret-up-fill" style={{
						position: 'absolute',
						top: '30%',
						opacity: sortDirection === 'asc' ? '1' : '0.5'
					}}></i>
					<i className="bi bi-caret-down-fill" style={{
						position: 'absolute',
						top: '50%',
						opacity: sortDirection === 'desc' ? '1' : '0.5'
					}}></i>
				</span>
			)}
		</th>
	);
};

export default SortableColumn;