import React, {FC, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import * as ReceiptCRUD from "./ReceiptCRUD";
import _ from "lodash";
import {formatPrice} from "../../../../selectors";
import {Link, useHistory} from "react-router-dom";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {LIST_PERMISSIONS} from "../../../../constants/common";
import axios, {AxiosError} from "axios";
import {IAxiosError} from "../../../../models/AxiosModel";
import {HTTP_UNPROCESSABLE_ENTITY} from "../../../../constants/status";
import {Toast} from "../../../../modules/common/helper";
import {ISearchProduct} from "../../../../models/OrderModel";
import {PaginateModel} from "../../../../models/PaginateModel";
import {Product4ReceiptModel} from "../../../../models/ReceiptlModel";
import {DATA_PAGINATION_LENGTH, PaginationLength} from "../../../../../_metronic/partials/pagination/PaginationLength";
import {Paginate} from "../../../../../_metronic/partials/pagination/Paginate";
import ProductTable from "./ProductTable";
import {usePermissionChecker} from "../../../../hooks/PermissionChecker";

const Create: FC = () => {

  const initSearch = {
    page: 1,
    per_page: 10,
  }

  const intl = useIntl();
  const history = useHistory();

  const schema = Yup.object().shape({});

  const [products, setProducts] = useState<PaginateModel<Product4ReceiptModel>>()
  const [placedProducts, setPlacedProducts] = useState<Product4ReceiptModel[]>([])
  const [search, setSearch] = useState<ISearchProduct>(initSearch)
  const {hasPermission} = usePermissionChecker();

  useEffect(() => {
		document.title = 'Sento - Tạo đơn hàng nhập';
	})

  useEffect(() => {
    const params = {...search}
    getListProducts(params);
  }, []);

  const getListProducts = async (params: ISearchProduct) => {
    let {data} = await ReceiptCRUD.getProductList({...params});
    setProducts(data);
  }

  const handlePageLengthChange = (e: any) => {
    getListProducts({...search, page: 1, per_page: +e.target.value})
  }

  const handlePageChange = (data: any) => {
    getListProducts({...search, page: data.selected + 1})
  }

  const onChangeTable = (item: Product4ReceiptModel, index?: number) => {
    let newPlaceItems = [...placedProducts];
    if (!index) {
      let fIndex = _.findIndex(placedProducts, {id: item.id});
      if (fIndex > -1) {
        newPlaceItems[fIndex] = item
      } else {
        newPlaceItems = [...newPlaceItems, item]
      }
    } else {
      newPlaceItems[index] = item;
    }
    setPlacedProducts(newPlaceItems)
  }

  const handleSubmit = async (values: any, {setFieldError}: FormikHelpers<any>) => {
    try {
      const {data} = await ReceiptCRUD.addReceipt(placedProducts)
      Toast.fire({
        icon: 'success',
        title: data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
      })
      history.push('/warehouse/receipt')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<IAxiosError>;
        if (axiosError.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const errors = axiosError.response?.data?.errors ?? []
          Object.keys(errors)?.map((key: string) => setFieldError(key, errors[key][0]));
        }
      }
    }
  }

  const onSearchProducts = (e: any) => {
    setSearch({...search, [e.target.name]: e.target.value});
    getListProducts({...search, [e.target.name]: e.target.value});
  }

  const onChangePlacedProd = (e: any) => {
    const checked = e.target.checked
    let selectedIds = _.map(placedProducts, 'id');
    if (selectedIds.length > 0 && checked) {
      let newSearch = {
        ...initSearch,
        order_by_ids: selectedIds
      }
      getListProducts(newSearch);
    }
  }

  const totalPrice = useMemo(() => {
    var res = 0;
    placedProducts.map((e: any) => {
      if (e.receipt_quantity && e.receipt_quantity > 0) {
        let supplier = e.new_supplier ?? e.best_supplier;
        let unitPrice = e['best_supplier.new_price'] ?? (supplier?.price ?? e.price);
        res += (e.receipt_quantity * unitPrice)
      }
    })
    return res;
  }, [placedProducts])

  const handleKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onSearchProducts(e);
      }
  }

  const handleCheckAll = (e: any) => {
    let placePds = [...placedProducts];
    let curPDatas = products?.data ?? [];
    if (e.target.checked) {
      _.map(curPDatas, (el: any) => {
          if (!_.some(placePds, { id: el.id })) {
            placePds = [...placePds, el]
          }
      });
    } else {
      let curPIds = _.map(curPDatas, 'id');
      placePds = _.filter(placePds, function(o) { return !curPIds.includes(o.id) });
    }
    setPlacedProducts(placePds)
  }
  return <Formik enableReinitialize validationSchema={schema}
                 initialValues={{receipt_code: null, status: null, note: null}} onSubmit={handleSubmit}>
    {({values, handleChange}) => {
      return (
        <Form>
          <div className="card">
            <div className="card-body">
              <div className="row mt-2">
                <label htmlFor="name"
                       className="col-4 col-md-2 col-form-label mb-2">{intl.formatMessage({id: 'PRODUCTS.INFO'})}</label>
                <div className="col-12 col-md-4 mb-2">
                  <input
                    type='text'
                    className='form-control'
                    name="name"
                    onBlur={(e) => onSearchProducts(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </div>
                <div className="col-md-3 mb-2">
                  <select
                    className="form-select"
                    value={search.wh_status}
                    onChange={(e) => onSearchProducts(e)}
                    name="wh_status"
                  >
                    <option value="">Tất cả</option>
                    <option value="1">Dưới mức tồn kho</option>
                    <option value="2">Vượt mức tồn kho</option>
                    <option value="3">Còn hàng trong kho</option>
                    <option value="4">Hết hàng trong kho</option>
                  </select>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="placed_products"
                           name='placed_products'
                           onChange={onChangePlacedProd}/>
                    <label htmlFor="placed_products" className="form-check-label mb-2">Hiển thị sản phẩm đặt</label>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <h4>{intl.formatMessage({id: 'ORDER.TOTAL'})}:</h4>
                <h3 className="text-primary ps-2"
                    key={`total_${totalPrice}`}>
                  {formatPrice(Math.round(totalPrice))}
                </h3>
              </div>
              <ProductTable
                products={products?.data}
                placedProducts={placedProducts}
                onChangeTable={onChangeTable}
                handleCheckAll={handleCheckAll}
              />
              <div className="d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]}
                                  onChange={handlePageLengthChange}/>
                <Paginate
                  pageCount={products?.meta?.last_page || 0}
                  onPageChange={handlePageChange}
                  forcePage={(search.page || 1) - 1}
                />
              </div>
            </div>
            <div className='card-footer py-6'>
              <div className="d-flex justify-content-center">
                <button className="btn btn-success" type="submit">{intl.formatMessage({id: 'COMMON.SAVE'})}</button>
                <Link to="/warehouse/inventody-delivery/list">
                  <button className="btn btn-secondary ms-2">{intl.formatMessage({id: 'COMMON.BACK'})}</button>
                </Link>
              </div>
            </div>
          </div>
        </Form>
      )
    }}
  </Formik>
}
export default Create;