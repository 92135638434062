import React, { FC, useMemo } from 'react';
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { formatPrice } from '../../../selectors';
import { AbilityModel } from '../../../models/AbilityModel';
import { HeaderModel } from '../../../models/CommonModel';
import SortableColumn from '../../../components/Table/SortableColumn';

type Props = {
    data: Array<AbilityModel>
    handleExportPdf: (id: number) => void,
    header: HeaderModel[],
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void,
    hideConfig?: boolean
};

const PaymentVoucherTable: FC<Props> = ({ data, handleExportPdf, header, hideCols, openColConfig, handleSort, hideConfig }) => {
    const intl = useIntl();

    const history = useHistory();
    return (
        <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        {!hideConfig && (
                            <th>
                                <i
                                    className="fa fa-cogs"
                                    style={{ cursor: 'pointer' }}
                                    onClick={openColConfig}
                                ></i>
                            </th>
                        )}
                        {header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: AbilityModel, index: number) => {
                        return (
                            <tr key={`ability_${index}`} className="cursor-pointer">
                                {!hideConfig && (
                                <td></td>
                                )}
                                {!hideCols.includes('date') && (
                                <td>{item.date}</td>
                                )}
                                {!hideCols.includes('name') && (
                                <td>{item.name}</td>
                                )}
                                {!hideCols.includes('note') && (
                                <td>{item.note}</td>
                                )}
                                {!hideCols.includes('value') && (
                                <td>{formatPrice(item.value)}</td>
                                )}
                                <td>
                                    <button 
                                        className="btn btn-primary me-3" 
                                        type="button"
                                        onClick={() => handleExportPdf(item.id)}
                                    >
                                        {intl.formatMessage({id: 'ORDER.PRINT'})}
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default PaymentVoucherTable;