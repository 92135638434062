import React, {FC} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {useLocation, useParams} from "react-router-dom";
import ViewDetail from "./ViewDetail";
import Create from "./Create";

interface ILocation {
  receipt_code?: string;
}

const InventoryDeliveryDetailWrapper: FC = () => {
  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Đơn hàng xuất kho',
      path: '/warehouse/inventody-delivery',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const {id} = useParams<{ id?: string }>();
  const location = useLocation<ILocation>();

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}>{id ? (location.state?.receipt_code ?? id) : intl.formatMessage({id: "COMMON.CREATE"})}</PageTitle>
      {id ? <ViewDetail/> : <Create/>}
    </>
  )
}
export default InventoryDeliveryDetailWrapper;