import React from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'

interface LoginInfoProps {
    handleChangeStatus: (e: React.ChangeEvent<HTMLSelectElement>) => void
  formik?: any
}

const LoginInfo: React.FC<LoginInfoProps> = ({formik, handleChangeStatus}) => {
const intl = useIntl()
const getOptStatus = () => {
    return (
      <>
        <option value='1'>{intl.formatMessage({id: 'ACCOUNT.STATUS.ACTIVE'})}</option>
        <option value='0'>{intl.formatMessage({id: 'ACCOUNT.STATUS.INACTIVE'})}</option>
      </>
    )
}
  return (
    <div className='col-sm-8 row'>
      <div className='col-12 mb-3'>
        <label className='form-label' htmlFor='user_name'>
          {intl.formatMessage({id: 'ACCOUNT.USERNAME'})}
        </label>
        <input
          {...formik.getFieldProps('user_name')}
          id='user_name'
          name='user_name'
          type='text'
          className={clsx(
            'form-control form-control-lg',
            {'is-invalid': formik.touched.user_name && formik.errors.user_name},
            {
              'is-valid': formik.touched.user_name && !formik.errors.user_name,
            }
          )}
          placeholder='Nhập tên đăng nhập'
          autoComplete='off'
        />
        {formik.touched.user_name && formik.errors.user_name && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.user_name}</span>
          </div>
        )}
      </div>
      <div className='col-12 mb-3'>
        <label className='form-label' htmlFor='password'>
          {intl.formatMessage({id: 'ACCOUNT.PASSWORD'})}
        </label>
        <input
          {...formik.getFieldProps('password')}
          id='password'
          name='password'
          type='password'
          className={clsx(
            'form-control form-control-lg',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          placeholder='Nhập mật khẩu'
          autoComplete='off'
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.password}</span>
          </div>
        )}
      </div>

      <div className='col-12 mb-3'>
        <label className='form-label' htmlFor='status'>
          {intl.formatMessage({id: 'ACCOUNT.STATUS'})}
        </label>
        <select
          {...formik.getFieldProps('status')}
          id='status'
          name='status'
          className={clsx(
            'form-select form-select-lg',
            {'is-invalid': formik.touched.status && formik.errors.status},
            {
              'is-valid': formik.touched.status && !formik.errors.status,
            }
          )}
          autoComplete='off'
        >
          <option value=''>{intl.formatMessage({id: 'ACCOUNT.STATUS'})}</option>
          {getOptStatus()}
        </select>
        {formik.touched.status && formik.errors.status && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.status}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginInfo
