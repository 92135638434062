import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import OrderDetailWrapper from '../pages/Sales/Orders/detail/OrderDetailWrapper';
import AgentWrapper from '../pages/Sales/Agency/AgentWrapper';
import AgentDetailWrapper from '../pages/Sales/AgentDetail/AgentDetailWrapper';
import AgencyDebtWrapper from '../pages/Sales/Debt/agency/AgencyDebtWrapper';
import ProductsWrapper from '../pages/Sales/products/ProductsWrapper';
export function SalesRoutes() {
  const OrderWrapper = lazy(() => import('../pages/Sales/Orders/OrderWrapper'))
  const normalRoutes = [
    { path: '/my-orders', component: OrderWrapper },
    { path: '/my-orders/detail/:id?', component: OrderDetailWrapper },
    { path: '/agents', component: AgentWrapper },
    { path: '/agents/detail/:id?', component: AgentDetailWrapper },
    { path: '/debt/agency', component: AgencyDebtWrapper},
    { path: '/my-products', component: ProductsWrapper },
  ];
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {normalRoutes.map((route) => (
          <Route exact key={route.path} path={route.path} component={route.component} />
        ))}
        {/*<Redirect from='/' to='/dashboard' />*/}
      </Switch>
    </Suspense>
  );
}
